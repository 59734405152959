import React from 'react';
import { graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import ContentContainer from '../content-container';
import CardItem from './_components/card-item';
import { useTheme, useMediaQuery } from '@material-ui/core';

export const ModuleCardGridQueryFragment = graphql`
  fragment ModuleCardGrid on ContentfulModuleCardGrid {
    __typename
    name
    cards {
      ...CardItem
    }
    isHidden
  }
`;

export default ({ data }) => {
  const theme = useTheme();
  const isHandheld = useMediaQuery(theme.breakpoints.down('md'));
  const spacing = isHandheld ? 4 : 8;

  return (
    <ContentContainer>
      <Grid container spacing={spacing}>
        {data.cards.map((card, index) => (
          <Grid item key={`card-${index}`} xs={12} sm={6}>
            <CardItem data={card} />
          </Grid>
        ))}
      </Grid>
    </ContentContainer>
  );
};
