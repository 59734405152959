import React from 'react';
import { graphql } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography } from '@material-ui/core';
import Media from '../media';
import RenderHtml from '../../helpers/render-html';
import FadeWrapper from '../fade-wrapper';
import ContentContainer from '../content-container';
import LinkButton from '../link-button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const ModuleShowcaseQueryFragment = graphql`
  fragment ModuleShowcase on ContentfulModuleShowcase {
    __typename
    name
    header
    body {
      childMarkdownRemark {
        html
      }
    }
    link {
      ...Link
    }
    image {
      fluid(quality: 100, maxWidth: 1120) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    fade
    isHidden
  }
`;

const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: theme.spacing(2),
  },
  text: {
    textAlign: 'center',
  },
}));

export default ({ data, options, index }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width:1080px)');

  return (
    <ContentContainer>
      <FadeWrapper fade={data.fade} condition={isDesktop}>
        <Grid container>
          <Grid item xs={12}>
            <section className={classes.text}>
              <Typography variant={options.headerVariant[index]} className={classes.header}>
                {data.header}
              </Typography>
              <RenderHtml html={data.body}></RenderHtml>
              {data.link && <LinkButton link={data.link} />}
            </section>
          </Grid>
          {data.image && (
            <Grid item xs={12}>
              <Media source={data.image} alt={data.image.name} />
            </Grid>
          )}
        </Grid>
      </FadeWrapper>
    </ContentContainer>
  );
};
