import React from 'react';
import { graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import ContentContainer from '../content-container';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import chunk from 'lodash-es/chunk';
import ListItemWithIcon from './_components/list-item-with-icon';
import { makeStyles } from '@material-ui/styles';
import RenderHtml from '../../helpers/render-html';

export const ModuleListWithIconsFragment = graphql`
  fragment ModuleListWithIcons on ContentfulModuleListWithIcons {
    __typename
    name
    header
    body {
      childMarkdownRemark {
        html
      }
    }
    items {
      ...ListItemWithIcon
    }
    columns
    isHidden
  }
`;

const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: 10,
  },
  root: {
    padding: 0,
  },
}));

export default ({ data, options }) => {
  const length = data.items.length;
  let chunkSize = length / data.columns;
  if (length % 2 === 1) chunkSize += 1;
  const lists = chunk(data.items, chunkSize);
  const classes = useStyles();
  const minColumns = Math.max(1, data.columns - 1);

  return (
    <ContentContainer>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h2' className={classes.header}>
            {data.header}
          </Typography>
          <RenderHtml html={data.body}></RenderHtml>
        </Grid>
        {lists.map((list, listIndex) => (
          <Grid item xs={12} sm={12 / minColumns} md={12 / data.columns} key={`list-${listIndex}`}>
            <List classes={{ root: classes.root }} disablePadding={true}>
              {list.map((listItem, listItemIndex) => (
                <ListItemWithIcon data={listItem} key={`listItem-${listIndex}-${listItemIndex}`} />
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
    </ContentContainer>
  );
};
