import React from 'react';
import { graphql } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography } from '@material-ui/core';
import RenderHtml from '../../helpers/render-html';
import ContentContainer from '../content-container';
import Gallery from 'react-grid-gallery';

export const ModuleGalleryQueryFragment = graphql`
  fragment ModuleGallery on ContentfulModuleGallery {
    __typename
    name
    header
    text {
      childMarkdownRemark {
        html
      }
    }
    entries {
      ...ItemGalleryEntry
    }
    rowHeight
    isHidden
  }
`;

const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: theme.spacing(2),
  },
}));

export default ({ data, options, index }) => {
  const classes = useStyles();

  const entries = data.entries || [];
  const images = entries.map(entry => ({
    src: entry.image.file.url,
    thumbnail: entry.thumbnail.file.url,
    thumbnailWidth: entry.width,
    thumbnailHeight: data.rowHeight,
    nano: entry.nano.base64,
    tags: entry.tags ? entry.tags.map(tag => ({ value: tag, title: tag })) : undefined,
    alt: entry.caption,
    caption: entry.caption,
  }));

  return (
    <ContentContainer>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant={options.headerVariant[index]} className={classes.header}>
            {data.header}
          </Typography>
          <RenderHtml html={data.text}></RenderHtml>
        </Grid>
        <Grid item xs={12}>
          <Gallery
            margin={4}
            backdropClosesModal={true}
            images={images}
            enableImageSelection={false}
            rowHeight={data.height}
            showLightboxThumbnails={true}
            lightBoxProps={{ preventScroll: false }}
            imageCountSeparator={' z '}
          />
        </Grid>
      </Grid>
    </ContentContainer>
  );
};
