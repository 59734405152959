import React from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import RenderHtml from '../../../helpers/render-html';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';

export const AddressBlockQueryFragment = graphql`
  fragment AddressBlock on ContentfulBlockCompanyAddress {
    __typename
    name
    address {
      childMarkdownRemark {
        html
      }
    }
    phone
    email
    openingHours {
      childMarkdownRemark {
        html
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  address: {
    '& section:not(:last-of-type)': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(1),
      },
    },
  },
  multiline: {
    '& p': {
      marginBottom: 0,
    },
  },
}));

export default ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.address}>
      <section>
        <Typography variant='h5'>Telefon:</Typography>
        <Link variant='body1' href={data.phone}>
          📞 {data.phone}
        </Link>
      </section>
      <section>
        <Typography variant='h5'>Email:</Typography>
        <Link variant='body1' href={`mailto:${data.email}`}>
          📧 {data.email}
        </Link>
      </section>
      <section>
        <RenderHtml className={classes.multiline} html={data.address}></RenderHtml>
      </section>
      <section>
        <RenderHtml className={classes.multiline} html={data.openingHours}></RenderHtml>
      </section>
    </div>
  );
};
