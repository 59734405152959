import React from 'react';
import { graphql } from 'gatsby';

export const MapQueryFragment = graphql`
  fragment Map on ContentfulItemMap {
    name
    source {
      source
    }
  }
`;

export default ({ data, onLoadHandler }) => {
  return (
    <iframe
      src={data.source.source}
      frameBorder='0'
      width='100%'
      height='100%'
      style={{ border: 0, minHeight: '300px' }}
      onLoad={onLoadHandler}
      allowFullScreen={true}></iframe>
  );
};
