import React from 'react';
import { graphql } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography } from '@material-ui/core';
import RenderHtml from '../../helpers/render-html';
import Fade from 'react-reveal/Fade';
import get from 'lodash-es/get';
import ConditionalWrapper from '../../helpers/conditional-wrapper';
import ContentContainer from '../content-container';

export const ModuleTextQueryFragment = graphql`
  fragment ModuleText on ContentfulModuleText {
    __typename
    name
    header
    anchorId
    body {
      childMarkdownRemark {
        html
      }
    }
    isHidden
  }
`;

const useStyles = makeStyles({
  header: {
    paddingBottom: 10,
  },
});

export default ({ data, options, index }) => {
  const classes = useStyles();
  const fade = get(options, 'moduleText.fade');

  return (
    <ContentContainer>
      <ConditionalWrapper condition={fade} wrapper={children => <Fade {...fade}>{children}</Fade>}>
        <Grid container>
          <Grid item xs={12} sm={10} md={9} id={data.anchorId}>
            {data.header && (
              <Typography variant={options.headerVariant[index]} className={classes.header}>
                {data.header}
              </Typography>
            )}
            {data.body && <RenderHtml html={data.body}></RenderHtml>}
          </Grid>
        </Grid>
      </ConditionalWrapper>
    </ContentContainer>
  );
};
