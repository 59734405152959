import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

export const MetadataQueryFragment = graphql`
  fragment PageMetadata on ContentfulPageMetaData {
    title
    metaDescription
    ogTitle
    ogImage {
      ...AssetUrl
    }
    ogDescription
  }
`;

const replacePageTitle = (text, pageTitle) =>
  text.replace('%pageTitle%', pageTitle);

const Metadata = ({ metadata, pageTitle }) => {
  if (!metadata) return <></>;

  return (
    <Helmet>
      {metadata.title && (
        <title>{replacePageTitle(metadata.title, pageTitle)}</title>
      )}
      {metadata.metaDescription && (
        <meta name="description" content={metadata.metaDescription} />
      )}
      {metadata.ogTitle && (
        <meta
          property="og:title"
          content={replacePageTitle(metadata.ogTitle, pageTitle)}
        />
      )}
      {metadata.ogImage && (
        <meta property="og:image" content={metadata.ogImage.file.url} />
      )}
      {metadata.ogDescription && (
        <meta property="og:description" content={metadata.ogDescription} />
      )}
    </Helmet>
  );
};

export default Metadata;
