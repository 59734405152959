import React from 'react';
import { graphql } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ReactSVG from 'react-svg';

export const ListItemWithIconQueryFragment = graphql`
  fragment ListItemWithIcon on ContentfulItemListItemWithIcon {
    __typename
    header
    secondaryText
    icon {
      ...AssetUrl
    }
  }
`;

const useStyles = makeStyles({
  icon: {
    width: '25px',
    height: '25px',
  },
});

export default ({ data }) => {
  const classes = useStyles();

  return (
    <ListItem>
      <ListItemIcon>
        <ReactSVG
          evalScripts='always'
          renumerateIRIElements={false}
          wrapper='span'
          src={data.icon.file.url}
          className={classes.icon}
          beforeInjection={svg => {
            svg.classList.add(classes.icon);
          }}
        />
      </ListItemIcon>
      <ListItemText primary={data.header} secondary={data.secondaryText ? data.secondaryText : null} />
    </ListItem>
  );
};
