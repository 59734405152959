import React from 'react';
import { graphql } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography } from '@material-ui/core';
import ContentContainer from '../content-container';
import BlockSimpleText from './_components/block-simple-text';

export const ModuleTwoColumnsQueryFragment = graphql`
  fragment ModuleTwoColumns on ContentfulModuleTwoColumns {
    __typename
    name
    header
    blocks {
      ...BlockSimpleText
    }
    isHidden
  }
`;

const useStyles = makeStyles({
  header: {
    paddingBottom: 10,
  },
});

export default ({ data, options }) => {
  const classes = useStyles();

  return (
    <ContentContainer>
      <Grid container spacing={2}>
        {data.header && (
          <Grid item xs={12}>
            <Typography variant={options.headerVariant} className={classes.header}>
              {data.header}
            </Typography>
          </Grid>
        )}
        {data.blocks.map((block, index) => (
          <Grid item key={`block-${index}`} xs={12} sm={6}>
            <BlockSimpleText data={block} />
          </Grid>
        ))}
      </Grid>
    </ContentContainer>
  );
};
