import React from 'react';
import { graphql } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography } from '@material-ui/core';
import Media from '../media';
import RenderHtml from '../../helpers/render-html';
import FadeWrapper from '../fade-wrapper';
import get from 'lodash-es/get';
import ContentContainer from '../content-container';
import LinkButton from '../link-button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const ModuleHeroSectionQueryFragment = graphql`
  fragment ModuleHeroSection on ContentfulModuleHeroSection {
    __typename
    name
    header
    body {
      childMarkdownRemark {
        html
      }
    }
    media {
      ...Media
    }
    link {
      ...Link
    }
    mediaOnTheLeft
    isHidden
  }
`;

const useStyles = makeStyles(theme => ({
  grayWrapper: {
    background: theme.palette.background.alternative,
  },
  whiteWrapper: {
    background: theme.palette.background.default,
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
}));

export default ({ data, options, index }) => {
  const classes = useStyles();
  const isHandheld = useMediaQuery('(max-width:800px)');
  const isDesktop = useMediaQuery('(min-width:1080px)');
  const isLeftSided = data.mediaOnTheLeft && !isHandheld;

  const spacing = get(options, 'moduleHeroSection.spacing', 4);
  const addBackground = get(options, 'moduleHeroSection.addBackground');
  const containerPadding = get(options, 'moduleHeroSection.containerPadding');
  const background = addBackground && !data.mediaOnTheLeft ? classes.grayWrapper : classes.whiteWrapper;

  const renderMedia = image => <Media source={image} alt={image.name} />;
  const renderText = data => (
    <section>
      <Typography variant={options.headerVariant[index]} className={classes.header}>
        {data.header}
      </Typography>
      <RenderHtml html={data.body}></RenderHtml>
      {data.link && <LinkButton link={data.link} />}
    </section>
  );

  const mediaSize = isHandheld ? 8 : 6;
  const textSize = isHandheld ? 12 : 6;

  return (
    <div className={background}>
      <ContentContainer className={background}>
        <FadeWrapper fade={data.fade} condition={isDesktop}>
          <Grid container spacing={spacing} style={{ padding: containerPadding }}>
            <Grid item xs={12} sm={isLeftSided ? mediaSize : textSize}>
              {isLeftSided ? renderMedia(data.media) : renderText(data)}
            </Grid>
            <Grid item xs={12} sm={isLeftSided ? textSize : mediaSize}>
              {isLeftSided ? renderText(data) : renderMedia(data.media)}
            </Grid>
          </Grid>
        </FadeWrapper>
      </ContentContainer>
    </div>
  );
};
