import React from 'react';
import { graphql } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Media from '../../../components/media';
import RenderHtml from '../../../helpers/render-html';

export const TileQueryFragment = graphql`
  fragment Tile on ContentfulItemTile {
    __typename
    name
    background {
      fluid(maxWidth: 120) {
        ...GatsbyContentfulFluid_tracedSVG
      }
    }
    asset {
      ...AssetUrl
    }
    link
    description {
      childMarkdownRemark {
        html
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  tileContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 120,
    width: '100%',
  },
  tileImageContainer: {
    padding: theme.spacing(1),
    flex: '0 0 auto',
    height: 120,
    width: 120,
  },
  tileBody: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  tileImage: {
    width: '100%',
    height: '100%',
  },
  tileLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.text.primary,
    },
    marginBottom: '4px',
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 'bold'
  },
  tileDescription: {
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  }
}));

export default ({ data }) => {
  const classes = useStyles();
  const getHref = data => (data.asset ? data.asset.file.url : data.link);

  return (
    <Grid item xs={12} key={data.name}>
      <Box boxShadow={3}>
        <div className={classes.tileContainer}>
          <Box boxShadow={1} className={classes.tileImageContainer}>
            <a href={getHref(data)} target='_blank' rel='noopener noreferrer'>
              <Media
                source={data.background}
                alt={data.name}
                className={classes.tileImage}
                imgStyle={{ objectFit: 'contain' }}
              />
            </a>
          </Box>
          <div className={classes.tileBody}>
            <a href={getHref(data)} className={classes.tileLink} target='_blank' rel='noopener noreferrer'>
              {data.name}
            </a>
            {data.description && <RenderHtml html={data.description} className={classes.tileDescription} />}
          </div>
        </div>
      </Box>
    </Grid>
  );
};
