import React from 'react';
import { graphql } from 'gatsby';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Media from '../../../components/media';
import { makeStyles, withStyles } from '@material-ui/core';
import RenderHtml from '../../../helpers/render-html';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

export const CardItemQueryFragment = graphql`
  fragment CardItem on ContentfulItemCard {
    __typename
    header
    summary {
      childMarkdownRemark {
        html
      }
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    image {
      ...Media
    }
  }
`;

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    padding: 0,
    borderBottom: 'none',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    padding: 0,
    '&$expanded': {
      padding: 0,
      marginTop: 12,
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    padding: 0,
  },
})(MuiExpansionPanelDetails);

const useStyles = makeStyles(theme => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
}));

export default ({ data }) => {
  const classes = useStyles();

  const renderText = data => <RenderHtml html={data}></RenderHtml>;
  const renderMedia = () => data.image && <Media source={data.image} alt={data.image.name} className={classes.media} />;
  const renderExpansion = () => (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel-content' id='panel-header'>
        {renderText(data.summary)}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{renderText(data.description)}</ExpansionPanelDetails>
    </ExpansionPanel>
  );

  return (
    <Card>
      {renderMedia()}
      <CardContent>
        <Typography variant='h3' className={classes.header}>
          {data.header}
        </Typography>
        {data.summary ? renderExpansion() : renderText(data.description)}
      </CardContent>
    </Card>
  );
};
