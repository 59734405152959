import React from 'react';
import { graphql } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import RenderHtml from '../../../helpers/render-html';

export const BlockSimpleTextQueryFragment = graphql`
  fragment BlockSimpleText on ContentfulBlockSimpleText {
    __typename
    header
    text {
      childMarkdownRemark {
        html
      }
    }
  }
`;

const useStyles = makeStyles({
  header: {
    paddingBottom: 10,
  },
});

export default ({ data, options }) => {
  const classes = useStyles();

  return (
    <article>
      <Typography variant='h2' className={classes.header}>
        {data.header}
      </Typography>
      <RenderHtml html={data.text}></RenderHtml>
    </article>
  );
};
