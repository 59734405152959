import React from 'react';
import Metadata from '../components/metadata';
import ModuleHeroSection from '../components/module-hero-section';
import ModuleText from '../components/module-text';
import ModuleTileGrid from '../components/module-tile-grid';
import ModuleContact from '../components/module-contact';
import ModuleShowcase from '../components/module-showcase';
import ModuleTwoColumns from '../components/module-two-columns';
import ModuleListWithIcons from '../components/module-list-with-icons';
import ModuleGallery from '../components/module-gallery';
import ModuleCardGrid from '../components/module-card-grid';
import get from 'lodash-es/get';

//Without type specified in query. Module comes with single "__typename" property.
//All the valid modules will have more properties
const isEmpty = data => Object.keys(data).length === 1;

const renderModule = (module, options, index) => {
  if (isEmpty(module)) {
    return <React.Fragment key={`missing-${index}`}></React.Fragment>;
  }

  if (module.isHidden) {
    return <React.Fragment key={`hidden-${index}`}></React.Fragment>;
  }

  const component = getComponentByType(module.__typename);
  return componentWithData(component, module, options, index);
};

const getComponentByType = type => {
  switch (type) {
    case 'ContentfulModuleHeroSection':
      return ModuleHeroSection;
    case 'ContentfulModuleText':
      return ModuleText;
    case 'ContentfulModuleTileGrid':
      return ModuleTileGrid;
    case 'ContentfulModuleContact':
      return ModuleContact;
    case 'ContentfulModuleShowcase':
      return ModuleShowcase;
    case 'ContentfulModuleTwoColumns':
      return ModuleTwoColumns;
    case 'ContentfulModuleListWithIcons':
      return ModuleListWithIcons;
    case 'ContentfulModuleGallery':
      return ModuleGallery;
    case 'ContentfulModuleCardGrid':
      return ModuleCardGrid;
    default:
      return null;
  }
};

const componentWithData = (component, module, options, index) => {
  if (!component) return null;
  const ComponentTag = component;
  return <ComponentTag data={module} options={options} index={index} key={`${module.name}-${index}`} />;
};

const parseOptions = pageData => {
  let options = {
    headerVariant: pageData.pageModules.map((_, index) => (index === 0 ? 'h1' : 'h2')),
  };
  const renderingOptions = get(pageData, 'renderingOptions.internal.content', undefined);
  if (renderingOptions) {
    options = { ...options, ...JSON.parse(renderingOptions) };
  }

  return options;
};

const renderPage = pageData => {
  if (!pageData || !pageData.pageModules) {
    return <></>;
  }

  const options = parseOptions(pageData);

  return (
    <React.Fragment>
      <Metadata metadata={pageData.customPageMetaData} pageTitle={pageData.name} />
      {pageData.pageModules.map((module, index) => renderModule(module, options, index))}
    </React.Fragment>
  );
};

export { renderModule, renderPage };
