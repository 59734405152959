import React from 'react';
import ConditionalWrapper from '../../helpers/conditional-wrapper';
import Fade from 'react-reveal/Fade';

const getFade = fade => {
  if (!fade) return null;
  const fadeEffect = fade.toLowerCase();
  return {
    [fadeEffect]: true,
  };
};

export default ({ fade, condition, children }) => {
  const fadeProps = condition ? getFade(fade) : null;
  const useFade = !!fadeProps;

  return (
    <ConditionalWrapper
      condition={useFade}
      wrapper={items => (
        <Fade {...fadeProps} duration={1000}>
          {items}
        </Fade>
      )}>
      {children}
    </ConditionalWrapper>
  );
};
