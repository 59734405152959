import { graphql } from 'gatsby';
import { renderPage } from '../../helpers/renderers';

export const TemplatePageQuery = graphql`
  query($contentful_id: String!) {
    contentfulPage(contentful_id: { eq: $contentful_id }) {
      name
      slug
      pageModules {
        ...ModuleHeroSection
        ...ModuleShowcase
        ...ModuleText
        ...ModuleTileGrid
        ...ModuleContact
        ...ModuleGallery
        ...ModuleCardGrid
      }
      customPageMetaData {
        ...PageMetadata
      }
      renderingOptions {
        internal {
          content
        }
      }
    }
  }
`;

export default ({ data: { contentfulPage: pageData } }) => renderPage(pageData);
