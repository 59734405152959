import React from 'react';
import { graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import ContentContainer from '../content-container';
import Tile from './_components/tile';

export const ModuleTileGridFragment = graphql`
  fragment ModuleTileGrid on ContentfulModuleTileGrid {
    __typename
    name
    tiles {
      ...Tile
    }
    isHidden
  }
`;

export default ({ data, options }) => {
  const tiles = data.tiles || [];

  return (
    <ContentContainer>
      <Grid container item spacing={3}>
        {tiles.map((tile, index) => (
          <Tile data={tile} key={`tile-${index}`} />
        ))}
      </Grid>
    </ContentContainer>
  );
};
