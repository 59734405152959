import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const useStyles = makeStyles(theme => ({
  buttonLink: {
    padding: theme.spacing(2, 3),
    minWidth: '220px',
    marginTop: theme.spacing(1),
    boxSizing: 'border-box',
    display: 'inline-block',
    textAlign: 'center',
    fontSize: '0.9rem',
    fontWeight: 400,
    color: theme.palette.link.secondary,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'none',
    margin: theme.spacing(2, 0),
    transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out',
    '&:hover': {
      color: theme.palette.link.secondary,
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
      textDecoration: 'none',
    },
  },
}));

export default ({ link }) => {
  const classes = useStyles();

  const internal = /^\/(?!\/)/.test(link.url);
  if (internal) {
    if (link.url.includes('#')) {
      return (
        <AnchorLink to={link.url} className={classes.buttonLink} title={link.buttonText}>
          {link.buttonText}
        </AnchorLink>
      );
    }

    return (
      <Link to={link.url} className={classes.buttonLink}>
        {link.buttonText}
      </Link>
    );
  }

  return (
    <a href={link.url} className={classes.buttonLink} target='_blank' rel='noopener noreferrer'>
      {link.buttonText}
    </a>
  );
};
