import React from 'react';
import { graphql } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Loader from 'react-loader-spinner';
import Map from './_components/item-map';
import AddressBlock from './_components/address-block';
import ContentContainer from '../content-container';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const ModuleContactQueryFragment = graphql`
  fragment ModuleContact on ContentfulModuleContact {
    __typename
    name
    address {
      ...AddressBlock
    }
    map {
      ...Map
    }
    isHidden
  }
`;

const useStyles = makeStyles({
  loaderContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    maxHeight: 300,
  },
  mapSection: {
    height: 310,
  },
});

export default ({ data }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const hideLoader = () => setLoading(false);
  const isHandheld = useMediaQuery('(max-width:1080px)');
  const spacing = isHandheld ? 2 : 8;

  return (
    <ContentContainer>
      <Grid container spacing={spacing}>
        <Grid item xs={12} sm={8}>
          <section className={classes.mapSection}>
            {loading ? (
              <div className={classes.loaderContainer}>
                <Loader type='Plane' color='#d86868' />
              </div>
            ) : null}
            <Map data={data.map} onLoadHandler={hideLoader} />
          </section>
        </Grid>
        <Grid item xs={12} sm={4}>
          <AddressBlock data={data.address} />
        </Grid>
      </Grid>
    </ContentContainer>
  );
};
